import { buttonsFn, currentPage } from "../common/layout";
import { debounce } from "throttle-debounce";

var loading = false;

const $window = $(window);
const $body = $(document.body);
const edtn = $body.data('edtn');

// add next page to list of available button functions
buttonsFn.nextPage = (data, element, response) => {
  var $button = $(element),
      $btnBox = $button.closest('.pager-next').parent('.cpre-center'),
      replaceContent = true;

  if(!$btnBox.length) return;

  if(
    !response ||
    response.constructor !== Object ||
    !response.status ||
    !response.html
  ) return;

  var $html = $(response.html);

  $body.addClass('m1-layout-silent-scroll'); // fix for MacOS overscroll

  if(('pageLoadMore' in currentPage)) {
    replaceContent = currentPage.pageLoadMore($html, $btnBox); 
  }

  if (replaceContent) {
    $btnBox.replaceWith($html);
  }

  loading = false;

  setTimeout(_ => {
    $body.removeClass('m1-layout-silent-scroll'); // fix for MacOS overscroll
  }, 1000);

  $window.trigger('m1-new-content', [$html]);
};

export default (options) => {
  var $nextBtn;

  options = $.extend({
    nextBtnSelector: '[data-id="nextPage"]',
    offset: document.body.clientHeight,
    delay: 200,
    preventState: false,
    preventAutoload: false,
  }, options);
  
  if (edtn === "de" || edtn === 'es' || edtn === 'ev_de'){
    if (window.location.href.includes('?p=') && (edtn === 'de' || edtn === 'ev_de')) {
      const headerHeight = document.querySelector('.m1-header-main').clientHeight;
      const elementPosY = document.getElementById('load_more_anchor').getBoundingClientRect().top;
      const scrollHeight = elementPosY - headerHeight * 2;
      window.scrollTo({
        top:scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
      return options.preventAutoload = true;
    }
    return options.preventAutoload = true;
  }
  if (options.preventAutoload) return;

  $window.on('scroll.load-more', debounce(options.delay, event => {
    if (loading) return;

    // don't waste time for finding btn, use cached reference
    if (
      !$nextBtn ||
      !document.contains || // IE
      !(typeof document.contains === 'function') || // IE
      !document.contains($nextBtn[0]) // element can change, check is cached el is still in DOM
    ) $nextBtn = $(options.nextBtnSelector);

    // if there are no load button we assume that it's last page
    // don't need to track scroll events anymore
    if (!$nextBtn.length) {
      $window.off('scroll.load-more');
      return;
    }

    const windowHeight = document.documentElement.clientHeight,
        button_rect = $nextBtn[0].getBoundingClientRect();

    if(button_rect.top - windowHeight > options.offset ) return;

    $nextBtn.trigger('click').removeAttr('data-button');
    loading = true;
    
    const url = $nextBtn.attr('href');

    $nextBtn = null;

    if(
      options.preventState || 
      typeof history.replaceState !== 'function' || 
      !url
    ) return;

    if (edtn === 'de' || edtn == 'ev_de') {
      history.replaceState(null, null, url);
    }
    
    $window.trigger('m1-url-changed', url);
  }));
};