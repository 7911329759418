let uniqueEventId = 0;

(function($, window, document){
  let pluginName = 'snapScrollControll',
      defaults = {
        onload: undefined,
        afterDestroy: undefined,
        bullets: false,
        duration: 300,
        animation: 'swing'
      };


  function SnapScrollControll(element, options) {
    this.element = element;
    this.$direction = $('body').data('rtl');
    this.slideNumber = 0;
    this.countSlide = 0;
    this.$scrollArea = $(element).find('.ss-slider-scroll-area');
    this.$slide = $(element).find('.ss-item:nth-child(2)');
    this.$pager = $(element).find('.ss-pager');
    this.$pagerItems = this.$pager.find('span');
    this.scroledEndFinish = true;
    this.iSiOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    this.scrolledPosition = 0;
    this.options = $.extend({}, defaults, options);
    this._defaults = defaults;
    this._name = pluginName;
    this.uniqueEventId = uniqueEventId++;
    this.init();
  }

  SnapScrollControll.prototype.init = function(){
    let options = this.options,
        $scrollArea = this.$scrollArea,
        scrollAreaWidth = this.$scrollArea.get(0).scrollWidth,
        $pagerItems = this.$pagerItems,
        sliderWidth = $(this.element).outerWidth(),
        slideWidth = Math.round(this.$slide.outerWidth(true));

    this.countSlide = $pagerItems.length - 1;

    $pagerItems.on('click', (e)=>{
      e.preventDefault();
      this.bulletclick(e);
    })

    if (typeof options.onload === 'function') {
      options.onload();
    }

    this.sliderWidth = $(this.element).outerWidth();
    this.hideArea = scrollAreaWidth - sliderWidth;
    this.slideWidth = slideWidth;
    this.scrollLeft = $scrollArea.scrollLeft();
    this.scrollAreaWidth = scrollAreaWidth;

    this.setuponeventclick();
    this.addremovecontrols();

    $scrollArea.on('scroll.snapScroll', this.scroll.bind(this));
    $scrollArea.on('scrollend.snapScroll', this.scrollend.bind(this));

    $(window).on(`resize.snapScroll${this.uniqueEventId}`, this.resize.bind(this));
  }

  SnapScrollControll.prototype.fadeinoutbutton = function(){
    var $slider = $(this.element),
        $controls = $slider.find('.ss-controls'),
        btnNext = $controls.find('.button-next'),
        btnPrev = $controls.find('.button-prev');

    if ($controls.length === 0) return;

    if (this.hideArea + this.sliderWidth === this.scrollAreaWidth) {
      btnPrev.css({
        opacity: 0.5,
        cursor: 'auto'
      });
      btnNext.css({
        opacity: 1,
        cursor: 'pointer'
      });
    } else if (this.hideArea === 0) {
      btnNext.css({
        opacity: 0.5,
        cursor: 'auto'
      });
      btnPrev.css({
        opacity: 1,
        cursor: 'pointer'
      });
    } else {
      btnNext.css({
        opacity: 1,
        cursor: 'pointer'
      });
      btnPrev.css({
        opacity: 1,
        cursor: 'pointer'
      });
    }
  }

  SnapScrollControll.prototype.setuponeventclick = function(){
    var $slider = $(this.element),
      $direction = this.$direction,
      $controls = $slider.find('.ss-controls'),
      btnNext = $controls.find('.button-next'),
      btnPrev = $controls.find('.button-prev');

    btnPrev.on('click.snapScroll', () => {
      if (this.scrollLeft == 0 || !this.scroledEndFinish) return;
      this.swipe('backward', $direction);
    });
    btnNext.on('click.snapScroll', () => {
      if (this.hideArea === 0 || !this.scroledEndFinish) return;
      this.swipe('forward', $direction);
    });
  }

  SnapScrollControll.prototype.addremovecontrols = function(){
    let $slider = $(this.element),
        sliderWidth = $slider.outerWidth(true),
        scrollWidth = this.scrollAreaWidth,
        $controls = $slider.find('.ss-controls'),
        isVisible = $controls.is(":visible"),
        controls = `
          <div class="ss-controls">
            <button class="ss-button button-prev" aria-label="slider controls button">
              <svg class="ico-nav" width="19" height="34" viewBox="0 0 19 34" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5684 33.5722C18.3234 32.9356 18.4313 31.8744 17.7841 31.1316L5.91946 18.1859C5.27229 17.5492 5.27229 16.4881 5.91946 15.8514L18.2156 2.90561C18.8627 2.26893 18.8627 1.10169 18.1077 0.46501C17.3527 -0.171668 16.2741 -0.171667 15.6269 0.571123L1.17359 15.8514C0.526429 16.4881 0.526429 17.5492 1.17359 18.1859L15.1955 33.4661C15.8426 34.1028 16.9212 34.2089 17.5684 33.5722Z"/>
              </svg>
            </button>
            <button class="ss-button button-next" aria-label="slider controls button">
              <svg class="ico-nav" width="17" height="33" viewBox="0 0 17 33" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.03822 31.6382C0.338222 31.0382 0.238222 30.0382 0.838222 29.3382L11.8382 17.1382C12.4382 16.5382 12.4382 15.5382 11.8382 14.9382L0.438222 2.73822C-0.161778 2.13822 -0.161778 1.03822 0.538222 0.438222C1.23822 -0.161778 2.23822 -0.161778 2.83822 0.538222L16.2382 14.9382C16.8382 15.5382 16.8382 16.5382 16.2382 17.1382L3.23822 31.5382C2.63822 32.1382 1.63822 32.2382 1.03822 31.6382Z"/>
              </svg>
            </button>
          </div>`;

    if ($controls.length === 0 && scrollWidth != sliderWidth && !isVisible && this.destroed !== true) {
      $slider.append(controls);
      this.setuponeventclick();
    } else if (scrollWidth <= sliderWidth && isVisible) {
      $controls.remove();
    }

    this.fadeinoutbutton();
  }

  SnapScrollControll.prototype.bulletclick = function(e){
    let $target = $(e.target),
        slideNumber = parseInt($target.text()),
        slidePosition = slideNumber * this.slideWidth,
        $scrollArea = this.$scrollArea,
        options = this.options;

    if (this.$direction) {
      slidePosition = slideNumber * this.slideWidth * (-1);
    } else {
      slidePosition = slideNumber * this.slideWidth;
    }

    this.slideNumber = slideNumber;

    if (this.iSiOS) {
      $scrollArea.animate({ scrollLeft: slidePosition }, options.duration, options.animation);
    } else {
      $scrollArea.scrollLeft(slidePosition);
    }

  }

  SnapScrollControll.prototype.scroll = function(){
    let $slide = this.$slide;

    this.scroledEndFinish = false;

    clearTimeout($.data(this, 'snapScroll'));
    $.data(this, 'snapScroll', setTimeout(function() {
      $slide.trigger('scrollend');
    }, 100));
  }

  SnapScrollControll.prototype.scrollend = function(){
    let $slider = $(this.element),
        $pagerItems = this.$pagerItems,
        $scrollArea = this.$scrollArea,
        scrollLeft = $scrollArea.scrollLeft(),
        coeficient = Math.abs(scrollLeft / this.slideWidth);

    this.slideNumber = coeficient;
    this.countSlide = coeficient;
    this.scrollLeft = scrollLeft;
    this.hideArea = this.scrollAreaWidth - (Math.abs(this.scrollLeft) + $slider.outerWidth(true));

    $pagerItems.removeClass('active').eq(this.slideNumber).addClass('active');
    $(window).trigger('scroll')

    this.fadeinoutbutton();

    this.scroledEndFinish = true;
  }

  SnapScrollControll.prototype.resize = function(){
    let $scrollArea = this.$scrollArea,
        scrollAreaWidth = $scrollArea.get(0).scrollWidth,
        sliderWidth = $(this.element).outerWidth(),
        slideWidth = Math.round(this.$slide.outerWidth(true));

    if($scrollArea.length === 0) return;

    this.sliderWidth = $(this.element).outerWidth();
    this.slideWidth = slideWidth;
    this.scrollLeft = $scrollArea.scrollLeft();
    this.scrollAreaWidth = scrollAreaWidth;
    this.hideArea = scrollAreaWidth - sliderWidth;

    this.addremovecontrols();

    $scrollArea.scrollLeft(0);
  }

  SnapScrollControll.prototype.swipe = function(direction, globalDirection){
    let $scrollArea = this.$scrollArea,
        slideWidth = this.slideWidth,
        scrollLeft = this.scrollLeft,
        options = this.options,
        destination = direction === 'backward' ? scrollLeft - slideWidth : scrollLeft + slideWidth;
    
    if (globalDirection) {
      destination = direction === 'backward' ? scrollLeft + slideWidth : scrollLeft - slideWidth;
    }

    direction === 'backward' ? this.slideNumber-- : this.slideNumber++;

    this.scrollLeft = destination

    if (this.iSiOS) {
      $scrollArea.animate({ scrollLeft: destination }, options.duration, options.animation);
    } else {
      $scrollArea.scrollLeft(destination);
    }

  }

  SnapScrollControll.prototype.destroy = function(){
    let $slider = $(this.element),
        options = this.options,
        $scrollArea = this.$scrollArea,
        $controls = $slider.find('.ss-controls'),
        btnNext = $controls.find('.button-next'),
        btnPrev = $controls.find('.button-prev');

    $(window).off(`resize.snapScroll${this.uniqueEventId}`);
    $slider.removeClass('.ss-slider');
    $scrollArea.removeClass('.ss-slider-scroll-area');
    btnNext.off('click.snapScroll');
    btnPrev.off('click.snapScroll');
    $controls.remove();

    this.destroed = true;


    $slider.data('snapScroll', false);

    if (typeof options.afterDestroy === 'function') {
      options.afterDestroy();
    }
  }

  $.fn[pluginName] = function ( options ) {
    return this.each(function () {
        if (!$.data(this, 'snapScroll')) {
            $.data(this, 'snapScroll',
            new SnapScrollControll( this, options ));
        }
    });
  }

}(jQuery, window, document))